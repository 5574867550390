import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
      memberstore:{},
      access_token:'',
  },
  getters: {
    access_token: state => {
      if (!state.access_token) {
        try {
          const access_token = localStorage.getItem('access_token')
          state.memberstore = access_token
        } catch (e) {
          console.error(e)
        }
      }
      return state.memberstore
    },
    memberstore: state => {
      if (!state.memberstore) {
        try {
          const user = localStorage.getItem('memberstore')
          state.memberstore = JSON.parse(user)
        } catch (e) {
          console.error(e)
        }
      }
      return state.memberstore
    },
   }, 
  mutations: {
      setMember (state, user) {
        state.memberstore = user
        localStorage.setItem('memberstore', JSON.stringify(user))
      },
      setToken (state, token) {
        state.access_token = token
        localStorage.setItem('access_token', token)
      },
      logout(state) {
        state = {}
      }
  },
  actions: {
  },
  modules: {
  }
})
