import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/login')
  },
  {
    path: '/sorting',
    name: 'sorting',
    component: () => import('@/views/sorting'),
    meta:{
        keepAlive:true
    }
  },{
    path: '/errorsorting',
    name: 'errorsorting',
    component: () => import('@/views/errorsorting')
  }, {
    path: '/',
    name: 'Home',
    component: () => import('@/views/home')
  }, {
    path: '/sorting_print',
    name: 'SortingPrint',
    component: () => import('@/views/sorting_print'),
    meta:{
        keepAlive:true
    }
  },
  {
    path: '/sortingdo',
    name: 'sortingdo',
    component: () => import('@/views/sortingdo'),
    meta:{
        keepAlive:true
    }
  },
  
  {
    path: '/outstocksummary',
    name: 'outstocksummary',
    component: () => import('@/views/outstocksummary')
  },
  {
    path: '/sort_operate',
    name: 'SortOperate',
    component: () => import('@/views/sort_operate'),
  },
  {
    path: '/printer',
    name: 'Printer',
    component: () => import('@/views/printer')
  },
  {
    path: '/single_sorting',
    name: 'singleSorting',
    component: () => import('@/views/single_sorting'),
    meta:{
      keepAlive:true
    }
  },{
    path: '/customer_sorting',
    name: 'customerSorting',
    component: () => import('@/views/customer_sorting'),
    meta:{
      keepAlive:true
    }
  },{
    path: '/customer_sorting_pro',
    name: 'customerSortingPro',
    component: () => import('@/views/customer_sorting_pro'),
    meta:{
      keepAlive:true
    }
  },
]

const router = new VueRouter({
  routes
})

export default router
