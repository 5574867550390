import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
import { requests } from '@/api/api.js'  // 引入请求公共网址
import { getLodop } from '@/assets/js/CLodopFuncs'
Vue.prototype.rq = requests
Vue.use(Antd);
Vue.config.productionTip = false


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
