import axios from 'axios'
import Message from 'ant-design-vue/es/message'
import router from '@/router/index'
const env = process.env

let baseUrl = '';
//本地
if (env.NODE_ENV === 'development') {
  baseUrl = `https://testcxcs.znsyun.com`; // 开发环境地址

  // baseUrl = 'https://cxcs.znsyun.com'
} else if (env.NODE_ENV === 'production') {
  // baseUrl = `https://testcxcs.znsyun.com`; // 开发环境地址
  baseUrl = 'https://cxcs.znsyun.com'
} else if (env.NODE_ENV === 'test') {
  baseUrl = 'https://testcxcs.znsyun.com'
}

// import Vue from 'vue'
// import store from '@/store'  // 我此项目没有用到vuex，所以vuex代码的都注释了，需要的自己打开使用

// import {ACCESS_TOKEN} from '@/store/mutation-types'



// 创建 axios 实例
const requests = axios.create({
//   baseURL: process.env.VUE_APP_API, // 基础url,如果是多环境配置这样写，也可以像下面一行的写死。
　baseURL: baseUrl,
   // baseURL: 'https://cxcs.znsyun.com',
  timeout: 6000 // 请求超时时间
})



 
// 错误处理函数
const err = (error) => {
  if (error.response) {
    const data = error.response.data
    // const token = Vue.ls.get(ACCESS_TOKEN)
    if (error.response.status === 403) {
        Message.warn(data.message||data.msg)
    }
    if (error.response.status === 401) {
         Message.warn('你没有权限。')
      // if (token) {
      //   store.dispatch('Logout').then(() => {
      //     setTimeout(() => {
      //       window.location.reload()
      //     }, 1500)
      //   })
      // }
    }
  }
  return Promise.reject(error)
}



// request interceptor(请求拦截器)
requests.interceptors.request.use(config => {
//   const token = Vue.ls.get(ACCESS_TOKEN)
  const token = localStorage.getItem('access_token')
  if(config.url == '/merapi/sorting/o-auth/login'){
       return config
  }else{
      if(token == undefined){
           router.push('/login')
          return;
      }else{
          if (config.url.indexOf('?') == -1) {
            config.url = config.url + '?access-token=' +token
          } else {
            config.url = config.url + '&access-token=' +token
          }
      }
  }
  return config
}, err)
// response interceptor（接收拦截器）
requests.interceptors.response.use((response) => {
  const res = response.data
  if (res.code !== 0&&res.code!==200) { 
    Message.warn(res.message||res.msg)
    // 401:未登录;
    if (res.code === 401||res.code === 403||res.code===999) {
        Message.warn('请登录')
        router.push('/login')
    }
    return Promise.reject('error')
  } else {
    return res
  }
}, err)



export  {
  requests
}