<template>
  <div id="app" style="transform-origin: top left;width: 1280px;height: 800px;position: absolute;overflow: hidden"
       :style="style">
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive && isRouterAlive"/>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive && isRouterAlive"/>
  </div>
</template>
<script>
  export default {
    name: "city",
    props: {},
    provide() {
      return {
        reload: this.reload
      }
    },
    data() {
      return {
        scalechange: 1,
        style: {},
        isRouterAlive: true
      };
    },
    mounted() {
      this.scalechange = document.body.clientWidth / 1280;
      this.style = {
        transform: 'scale(' + this.scalechange + ',' + this.scalechange + ')'
      }

      window.addEventListener('resize', () => {
        this.scalechange = document.body.clientWidth / 1280;
        this.style = {
          transform: 'scale(' + this.scalechange + ',' + this.scalechange + ')'
        }
      });
    },
    methods: {
      reload() {
        this.isRouterAlive = false;
        this.$nextTick(() => {
          this.isRouterAlive = true
        })
      }
    }
  }
</script>
<style>
  body {
    background: #666
  }
</style>
<style lang="less">

  #app {
    height: 100%;
    width: 100%;
  }

</style>
